import { I18n } from '@aws-amplify/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Container } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Auth } from 'aws-amplify';
import { routerActions } from 'connected-react-router';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import './App.scss';
import { useAppDispatch, useAppSelector } from './hooks';
import { COGNITO_TRANSLATIONS } from './i18';
import { Shell } from './pages/shell';
import { isLoading, stopLoading } from './slice/loadingSlice';
import { CognitoPayload, signIn, signOutAsync } from './slice/userSlice';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';
import Agree from './pages/start/agree';
import Start from './pages/start/start';
import SelectLang from './pages/start/select_lang';
import { ErrorBoundaryWrapper, ErrorPage } from './pages/error';
import { ERROR_CODE } from './constant/errorCode';
import { ERROR_MESSAGE } from './constant/message';
import { lazyRetry } from './utils/lazyRetry';
import useError from './hooks/useError/useError';
import { localStorageKeys } from './constant/localStorage';
import {
  GloabalAudioManager,
  defaultGloabalAudioManagerContext,
  useAudioUpdate,
} from './component/AudioProvider/AudioProvider';
import RouteWrapper from './component/RouteWrapper/RouteWrapper';

const Stories = lazy(() => lazyRetry(() => import('./pages/stories/index')));
const Grammar = lazy(() => lazyRetry(() => import('./pages/grammar/index')));
const Story = lazy(() => lazyRetry(() => import('./pages/story/index')));
const Expression = lazy(() => lazyRetry(() => import('./pages/expression/index')));
const Exercises = lazy(() => lazyRetry(() => import('./pages/exercises')));
const Question = lazy(() => lazyRetry(() => import('./pages/question/index')));
const Answer = lazy(() => lazyRetry(() => import('./pages/answer/index')));
const Goal = lazy(() => lazyRetry(() => import('./pages/goal/index')));
const OnlineLesson = lazy(() => lazyRetry(() => import('./pages/online_lesson/index')));
const SelfStudy = lazy(() => lazyRetry(() => import('./pages/self_study/index')));
const WordbankNoun = lazy(() => lazyRetry(() => import('./pages/wordbank_noun/index')));
const WordbankVerb = lazy(() => lazyRetry(() => import('./pages/wordbank_verb/index')));
const WordbankAdjective = lazy(() => lazyRetry(() => import('./pages/wordbank_adjective/index')));
const GrammarNotes = lazy(() => lazyRetry(() => import('./pages/grammar_notes/index')));
const GrammarList = lazy(() => lazyRetry(() => import('./pages/grammar_list/index')));
const Times = lazy(() => lazyRetry(() => import('./pages/times/index')));
const Dates = lazy(() => lazyRetry(() => import('./pages/dates/index')));
const DrillsList = lazy(() => lazyRetry(() => import('./pages/drills_list/index')));
const DrillsTop = lazy(() => lazyRetry(() => import('./pages/drills_top/index')));
const DrillsQuestion = lazy(() => lazyRetry(() => import('./pages/drills_question/index')));
const DrillsAnswer = lazy(() => lazyRetry(() => import('./pages/drills_answer/index')));
const DrillsGoal = lazy(() => lazyRetry(() => import('./pages/drills_goal/index')));
const Notices = lazy(() => lazyRetry(() => import('./pages/notices/index')));
const Settings = lazy(() => lazyRetry(() => import('./pages/settings/index')));
const UserInfo = lazy(() => lazyRetry(() => import('./pages/user_info/index')));
const Contact = lazy(() => lazyRetry(() => import('./pages/contact/index')));
const InstallationGuide = lazy(() => lazyRetry(() => import('./pages/installation_guide/index')));
const Signin = lazy(() => lazyRetry(() => import('./pages/signin/index')));
const WordCards = lazy(() => lazyRetry(() => import('./pages/word_cards/index')));
const Lessons = lazy(() => lazyRetry(() => import('./pages/lessons/index')));
const Lesson = lazy(() => lazyRetry(() => import('./pages/lesson/index')));
const QAndA = lazy(() => lazyRetry(() => import('./pages/q_and_a/index')));
const StudyRecords = lazy(() => lazyRetry(() => import('./pages/study_records/index')));
const Top = lazy(() => lazyRetry(() => import('./pages/top/top')));
const HiraganaAn = lazy(() => lazyRetry(() => import('./pages/hiragana_a-n/index')));
const HiraganaGaPo = lazy(() => lazyRetry(() => import('./pages/hiragana_ga-po/index')));
const HiraganaKyaPyo = lazy(() => lazyRetry(() => import('./pages/hiragana_kya-pyo/index')));
const HiraganaAnContents = lazy(() => lazyRetry(() => import('./pages/hiragana_a-n_contents/index')));
const HiraganaGaPoContents = lazy(() => lazyRetry(() => import('./pages/hiragana_ga-po_contents/index')));
const HiraganaKyaRyoContents = lazy(() => lazyRetry(() => import('./pages/hiragana_kya-ryo_contents/index')));
const HiraganaGyaPyoContents = lazy(() => lazyRetry(() => import('./pages/hiragana_gya-pyo_contents/index')));
const HiraganaExercises = lazy(() => lazyRetry(() => import('./pages/hiragana_exercises/index')));
const HiraganaQuestion = lazy(() => lazyRetry(() => import('./pages/hiragana_question/index')));
const HiraganaAnswer = lazy(() => lazyRetry(() => import('./pages/hiragana_answer/index')));
const HiraganaGoal = lazy(() => lazyRetry(() => import('./pages/hiragana_goal/index')));
const KatakanaAn = lazy(() => lazyRetry(() => import('./pages/katakana_a-n/index')));
const KatakanaGaPo = lazy(() => lazyRetry(() => import('./pages/katakana_ga-po/index')));
const KatakanaKyaPyo = lazy(() => lazyRetry(() => import('./pages/katakana_kya-pyo/index')));
const KatakanaAnContents = lazy(() => lazyRetry(() => import('./pages/katakana_a-n_contents/index')));
const KatakanaGaPoContents = lazy(() => lazyRetry(() => import('./pages/katakana_ga-po_contents/index')));
const KatakanaKyaRyoContents = lazy(() => lazyRetry(() => import('./pages/katakana_kya-ryo_contents/index')));
const KatakanaGyaPyoContents = lazy(() => lazyRetry(() => import('./pages/katakana_gya-pyo_contents/index')));
const KatakanaIeDouContents = lazy(() => lazyRetry(() => import('./pages/katakana_ie-dou_contents/index')));
const KatakanaFuyuDeyuContents = lazy(() => lazyRetry(() => import('./pages/katakana_fuyu-deyu_contents/index')));
const KatakanaExercises = lazy(() => lazyRetry(() => import('./pages/katakana_exercises/index')));
const KatakanaQuestion = lazy(() => lazyRetry(() => import('./pages/katakana_question/index')));
const KatakanaAnswer = lazy(() => lazyRetry(() => import('./pages/katakana_answer/index')));
const KatakanaGoal = lazy(() => lazyRetry(() => import('./pages/katakana_goal/index')));
const JoinLesson = lazy(() => import('./pages/join-lesson/pages/join-lesson/index'));
const Preview = lazy(() => import('./pages/join-lesson/pages/join-lesson/preview/index'));
const Gom = lazy(() => import('./pages/experiment/index'));

I18n.putVocabulariesForLanguage('ja', COGNITO_TRANSLATIONS);
const PrivateRoute = ({ render, userState, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={userState.authState === AuthState.SignedIn && userState.user ? render : () => <Redirect to="/signin" />}
    />
  );
};
export const Loading = () => (
  <Container className="App-Loading">
    <Spinner />
  </Container>
);

const extractCognitoPayload = (authenticatedUser: any): CognitoPayload => {
  try {
    return {
      'cognito:username': authenticatedUser.signInUserSession.idToken.payload['cognito:username'],
      'custom:companyId': authenticatedUser.attributes['custom:companyId'] || '',
      'custom:learnerId': authenticatedUser.attributes['custom:learnerId'],
      email: authenticatedUser.signInUserSession.idToken.payload.email,
      exp: authenticatedUser.signInUserSession.idToken.payload.exp,
    };
  } catch (error) {
    throw error;
  }
};

const registerCOIServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.getRegistration('/coi-serviceworker.js');
      if (!registration) {
        await navigator.serviceWorker.register('/coi-serviceworker.js');
      }
    } catch (error) {
      console.error('COI Service Worker registration failed:', error);
    }
  }
};

const App = () => {
  const userState = useAppSelector((state) => state.user);
  const isloading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { sendErrorLog } = useError();
  useEffect(() => {
    // // ブラウザバックを禁止する
    // // window.history.pushState(null, "null", null);
    // window.addEventListener("popstate", function() {
    //   alert('ブラウザバックを使わないでください。');
    //   window.history.go(1);
    // });
    registerCOIServiceWorker();
    let authenticatedUser = {};
    const init = async () => {
      try {
        authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
      } catch (e) {}
      if (authenticatedUser) {
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
        dispatch(stopLoading());
      } else {
        // FIXME サインイン前の全ての画面でサインアウトが実施される
        await dispatch(signOutAsync());
        dispatch(stopLoading());
        // cognito認証前のページ以外はサインインページへ遷移
        if (
          location.pathname !== '/' &&
          location.pathname !== '/agree' &&
          location.pathname !== '/start' &&
          location.pathname !== '/select_lang'
        ) {
          dispatch(routerActions.push('/signin'));
        }
      }
    };
    init();
    return onAuthUIStateChange((nextAuthState, authenticatedUser) => {
      if (nextAuthState === AuthState.SignedIn && authenticatedUser) {
        localStorage.setItem(localStorageKeys.mode, '');
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
      }
    });
  }, [dispatch]);

  return isloading ? (
    <ErrorBoundaryWrapper>
      <Loading />
    </ErrorBoundaryWrapper>
  ) : (
    <ErrorBoundaryWrapper>
      <RouteWrapper>
        <GloabalAudioManager.Provider value={defaultGloabalAudioManagerContext}>
          <Switch>
            <Route exact path="/select_lang">
              <SelectLang />
            </Route>
            <Route exact path="/agree">
              <Agree />
            </Route>
            <Route
              exact
              path="/start"
              render={() => {
                // Routing判定でstartページへ行ける？
                return localStorage.getItem(localStorageKeys.agreed) === 'true' ? (
                  <Start />
                ) : (
                  <Redirect to="/select_lang" />
                );
              }}
            />
            <React.Suspense fallback={<Loading />}>
              {location.pathname === '/top' ? (
                <PrivateRoute exact path="/top" render={() => <Top />} userState={userState} />
              ) : (
                <Switch>
                  <PrivateRoute
                    exact
                    path="/join-lesson"
                    render={() => <JoinLesson />}
                    userState={userState}
                  />
                  <PrivateRoute exact path="/gom" render={() => <Gom />} userState={userState} />
                  <PrivateRoute exact path="/join-lesson/preview/:companyId/:roomId" render={() => <Preview />} userState={userState} />
                  <Shell>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <Redirect to="/top" />
                          ) : (
                            <Redirect to="/select_lang" />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/signin"
                        render={() => {
                          localStorage.setItem(localStorageKeys.mode, '');
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <Redirect to="/top" />
                          ) : localStorage.getItem(localStorageKeys.agreed) === 'true' ? (
                            <Signin />
                          ) : (
                            <Redirect to="/select_lang" />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/stories/:episodeId"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <Stories />
                          ) : (
                            <Signin />
                          );
                        }}
                      />
                      <PrivateRoute exact path="/grammar/:episodeId" render={() => <Grammar />} userState={userState} />
                      <PrivateRoute exact path="/story/:episodeId" render={() => <Story />} userState={userState} />
                      <PrivateRoute
                        exact
                        path="/expression/:episodeId"
                        render={() => <Expression />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/exercises/:episodeId"
                        render={() => <Exercises />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/question/:episodeId"
                        render={() => <Question />}
                        userState={userState}
                      />
                      <PrivateRoute exact path="/answer/:episodeId" render={() => <Answer />} userState={userState} />
                      <PrivateRoute exact path="/goal/:episodeId" render={() => <Goal />} userState={userState} />
                      <PrivateRoute exact path="/self_study" render={() => <SelfStudy />} userState={userState} />
                      <PrivateRoute exact path="/wordbank_noun" render={() => <WordbankNoun />} userState={userState} />
                      <PrivateRoute exact path="/wordbank_verb" render={() => <WordbankVerb />} userState={userState} />
                      <PrivateRoute
                        exact
                        path="/wordbank_adjective"
                        render={() => <WordbankAdjective />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/word_cards/:dataID/:idx/:index"
                        render={() => <WordCards />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/grammar_notes/:index"
                        render={() => <GrammarNotes />}
                        userState={userState}
                      />
                      <PrivateRoute exact path="/grammar_list" render={() => <GrammarList />} userState={userState} />
                      <PrivateRoute exact path="/times" render={() => <Times />} userState={userState} />
                      <PrivateRoute exact path="/dates" render={() => <Dates />} userState={userState} />
                      <PrivateRoute exact path="/drills_answer" render={() => <DrillsAnswer />} userState={userState} />
                      <PrivateRoute exact path="/drills_goal" render={() => <DrillsGoal />} userState={userState} />
                      <PrivateRoute exact path="/drills_list" render={() => <DrillsList />} userState={userState} />
                      <PrivateRoute
                        exact
                        path="/drills_question"
                        render={() => <DrillsQuestion />}
                        userState={userState}
                      />
                      <PrivateRoute exact path="/drills_top" render={() => <DrillsTop />} userState={userState} />
                      <PrivateRoute exact path="/online_lesson" render={() => <OnlineLesson />} userState={userState} />
                      <PrivateRoute exact path="/notices" render={() => <Notices />} userState={userState} />
                      <PrivateRoute exact path="/settings" render={() => <Settings />} userState={userState} />
                      <PrivateRoute exact path="/q_and_a" render={() => <QAndA />} userState={userState} />
                      <PrivateRoute exact path="/study_records" render={() => <StudyRecords />} userState={userState} />
                      <PrivateRoute exact path="/hiragana_a-n" render={() => <HiraganaAn />} userState={userState} />
                      <PrivateRoute
                        exact
                        path="/hiragana_ga-po"
                        render={() => <HiraganaGaPo />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_kya-pyo"
                        render={() => <HiraganaKyaPyo />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_a-n_contents/:id"
                        render={() => <HiraganaAnContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_ga-po_contents/:id"
                        render={() => <HiraganaGaPoContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_kya-ryo_contents/:id"
                        render={() => <HiraganaKyaRyoContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_gya-pyo_contents/:id"
                        render={() => <HiraganaGyaPyoContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_exercises"
                        render={() => <HiraganaExercises />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_question"
                        render={() => <HiraganaQuestion />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/hiragana_answer"
                        render={() => <HiraganaAnswer />}
                        userState={userState}
                      />
                      <PrivateRoute exact path="/hiragana_goal" render={() => <HiraganaGoal />} userState={userState} />
                      <PrivateRoute exact path="/katakana_a-n" render={() => <KatakanaAn />} userState={userState} />
                      <PrivateRoute
                        exact
                        path="/katakana_ga-po"
                        render={() => <KatakanaGaPo />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_kya-pyo"
                        render={() => <KatakanaKyaPyo />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_a-n_contents/:id"
                        render={() => <KatakanaAnContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_ga-po_contents/:id"
                        render={() => <KatakanaGaPoContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_kya-ryo_contents/:id"
                        render={() => <KatakanaKyaRyoContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_gya-pyo_contents/:id"
                        render={() => <KatakanaGyaPyoContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_ie-dou_contents/:id"
                        render={() => <KatakanaIeDouContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_fuyu-deyu_contents/:id"
                        render={() => <KatakanaFuyuDeyuContents />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_exercises"
                        render={() => <KatakanaExercises />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_question"
                        render={() => <KatakanaQuestion />}
                        userState={userState}
                      />
                      <PrivateRoute
                        exact
                        path="/katakana_answer"
                        render={() => <KatakanaAnswer />}
                        userState={userState}
                      />
                      <PrivateRoute exact path="/katakana_goal" render={() => <KatakanaGoal />} userState={userState} />
                      <Route
                        exact
                        path="/user_info"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <UserInfo />
                          ) : (
                            <Signin />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/contact"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <Contact />
                          ) : (
                            <Signin />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/installation_guide"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <InstallationGuide />
                          ) : (
                            <Signin />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/lesson"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? <Lesson /> : <Signin />;
                        }}
                      />
                      <Route
                        exact
                        path="/lessons"
                        render={() => {
                          return userState.authState === AuthState.SignedIn && userState.user ? (
                            <Lessons />
                          ) : (
                            <Signin />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="*"
                        render={() => {
                          return (
                            <ErrorPage
                              errorCode={ERROR_CODE.HTTP_404}
                              error={new Error(ERROR_MESSAGE.HTTP_404.ja + ` url:${document.location.href}`)}
                              message={ERROR_MESSAGE.HTTP_404.ja}
                            />
                          );
                        }}
                      />
                    </Switch>
                  </Shell>
                </Switch>
              )}
            </React.Suspense>
            <Route
              exact
              path="*"
              render={() => {
                return (
                  <ErrorPage
                    errorCode={ERROR_CODE.HTTP_404}
                    error={new Error(ERROR_MESSAGE.HTTP_404.ja + ` url:${document.location.href}`)}
                    message={ERROR_MESSAGE.HTTP_404.ja}
                  />
                );
              }}
            />
          </Switch>
        </GloabalAudioManager.Provider>
      </RouteWrapper>
    </ErrorBoundaryWrapper>
  );
};

//インストールされたPWAのウインドーのサイズ
// window.addEventListener('resize', function () {
//   let fixedWidth = 375;
//   let fixedHeight = 667;
//   window.resizeTo(fixedWidth, fixedHeight);
// });

export const exchangeTimeZone = (datedime: Date) => {
  // console.log(datedime);
  // ローカル時間に変更
  let localDatetime = datedime.toLocaleString();
  // 出力用にフォーマット
  let fromattedTime = format(Date.parse(localDatetime), 'yyyy/MM/dd HH:mm:ss');
  // console.log(fromattedTime);
  return fromattedTime;
};
export const exchangeTimeZone2 = (datetime: Date) => {
  let today = datetime;
  if (typeof datetime == 'string') {
    today = new Date(today);
  }
  // 出力用にフォーマット(入力値をそのまま出力)
  let fromattedTime =
    today.getUTCFullYear() +
    '/' +
    (today.getUTCMonth() + 1).toString().padStart(2, '0') +
    '/' +
    today.getUTCDate().toString().padStart(2, '0') +
    ' ' +
    today.getUTCHours().toString().padStart(2, '0') +
    ':' +
    today.getUTCMinutes().toString().padStart(2, '0') +
    ':' +
    today.getUTCSeconds().toString().padStart(2, '0');
  return fromattedTime;
};

export const formatYMD = (YMD: string) => {
  return `${YMD.substring(0, 4)}/${YMD.substring(4, 6)}/${YMD.substring(6, 8)}`;
};

export default App;
